import {
  CheckCircleIcon,
  FireIcon,
  TvIcon,
  WrenchIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import type { event_short, news_short } from "@prisma/client";
import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { EventType, NewsType } from "~/constants/prismaEnums";
import { classNames } from "~/utils/misc";
import { printSafeDateString } from "~/utils/tools";
import type { importantTask } from "./_index";

interface StartPageProps {
  isCompanyAccount: boolean;
  news: news_short[];
  events: event_short[];
  importantTasks: importantTask[];
}

export default function StartPage({
  isCompanyAccount,
  news,
  events,
  importantTasks,
}: StartPageProps) {
  let { t } = useTranslation();

  return (
    <div className="bg-bg-light dark:bg-bg-dark">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <div className="grid gap-4 lg:grid-cols-3 lg:grid-rows-2">
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px border-2  border-button-light dark:border-button-dark rounded-lg bg-bg-light dark:bg-bg-dark lg:rounded-l-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-text-ligh dark:text-text-dark max-lg:text-center">
                  {t("news")}
                </p>
                <div className="my-auto mx-auto text-black dark:text-white justify-center justify-items-center">
                  <div>
                    <p className="mt-1 text-sm text-black dark:text-white">
                      {t("newsText")}
                    </p>
                    <ul className="mt-6 grid grid-cols-1 gap-3 border-t border-gray-200 py-6">
                      {news.map((item, itemIdx) => (
                        <li key={itemIdx} className="flow-root">
                          <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-button-light dark:hover:bg-button-dark">
                            <div
                              className={classNames(
                                item.news_type === NewsType.Default
                                  ? "bg-green-500"
                                  : "bg-red-500",
                                "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                              )}
                            >
                              {item.news_type === NewsType.Default && (
                                <FireIcon
                                  aria-hidden="true"
                                  className="h-6 w-6 text-white"
                                />
                              )}
                            </div>
                            <div>
                              <h3 className="text-sm font-medium text-black dark:text-white">
                                <Link to="#" className="focus:outline-none">
                                  <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                  />
                                  <span>{t(item.title)}</span>
                                </Link>
                              </h3>
                              <p className="mt-1 text-sm text-black dark:text-white">
                                {t(item.description)}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative max-lg:row-start-1 row-span-2">
            <div className="absolute inset-px border-2 border-button-light dark:border-button-dark rounded-lg bg-bg-light dark:bg-bg-dark max-lg:rounded-t-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-text-ligh dark:text-text-dark max-lg:text-center">
                  {t("importantTasks")}
                </p>
                <div className="my-auto mx-auto text-black dark:text-white justify-center justify-items-center">
                  <div>
                    <p className="mt-1 text-sm text-black dark:text-white">
                      {t("importantTasksText")}
                    </p>
                    <ul className="mt-6 grid grid-cols-1 gap-3  border-t border-gray-200 py-6">
                      {isCompanyAccount &&
                        importantTasks.map((item, itemIdx) => (
                          <li key={itemIdx} className="flow-root">
                            <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-button-light dark:hover:bg-button-dark">
                              <div
                                className={classNames(
                                  item.isDone ? "bg-green-500" : "bg-red-500",
                                  "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                                )}
                              >
                                {item.isDone ? (
                                  <CheckCircleIcon
                                    aria-hidden="true"
                                    className="h-6 w-6 text-white"
                                  />
                                ) : (
                                  <XCircleIcon
                                    aria-hidden="true"
                                    className="h-6 w-6 text-white"
                                  />
                                )}
                              </div>
                              <div>
                                <h3 className="text-sm font-medium text-black dark:text-white">
                                  <Link
                                    to={item.link}
                                    className="focus:outline-none"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    <span>{t(item.title)}</span>
                                  </Link>
                                </h3>
                                <p className="mt-1 text-sm text-black dark:text-white">
                                  {t(item.description)}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px border-2 border-button-light dark:border-button-dark rounded-lg bg-bg-light dark:bg-bg-dark max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-text-ligh dark:text-text-dark max-lg:text-center">
                  {t("upcomingEvents")}
                </p>
                <div className="my-auto mx-auto text-black dark:text-white justify-center justify-items-center">
                  <div>
                    <p className="mt-1 text-sm text-black dark:text-white">
                      {t("upcomingEventsText")}
                    </p>
                    <ul className="mt-6 grid grid-cols-1 gap-3 border-t border-gray-200 py-6">
                      {events.map((item, itemIdx) => (
                        <li key={itemIdx} className="flow-root">
                          <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-button-light dark:hover:bg-button-dark">
                            <div
                              className={classNames(
                                item.event_type === EventType.Maintenance
                                  ? "bg-yellow-400"
                                  : "bg-pink-600",
                                "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                              )}
                            >
                              {item.event_type === EventType.Stream && (
                                <TvIcon
                                  aria-hidden="true"
                                  className="h-6 w-6 text-white"
                                />
                              )}
                              {item.event_type === EventType.Maintenance && (
                                <WrenchIcon
                                  aria-hidden="true"
                                  className="h-6 w-6 text-white"
                                />
                              )}
                            </div>
                            <div>
                              <h3 className="text-sm font-medium text-black dark:text-white">
                                <Link to="#" className="focus:outline-none">
                                  <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                  />
                                  <span>{t(item.title)}</span>
                                  {" - "}
                                  {printSafeDateString(item.event_date)}
                                </Link>
                              </h3>
                              <p className="mt-1 text-sm text-black dark:text-white">
                                {t(item.description)}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
